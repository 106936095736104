import React from "react"
import { graphql } from "gatsby"
import { dateLarge } from "../utils/Utils"
import Page from "../components/page/Page"
import "./articulos-academicos.css"

export default props => {
  const articulosAcademicosEdges =
    props.data.allContentfulArticulosAcademicos.edges
  return (
    <Page
      class="articulos-academicos"
      slug="articulos-academicos"
      title="Artículos Académicos"
    >
      <div className="list">
        <header className="list-header">
          <h1 className="title">Artículos Académicos</h1>
          <div className="subtitle">
            Mis textos en revistas especializadas en Ciencias Sociales
          </div>
        </header>
        <main className="list-main">
          {articulosAcademicosEdges.map((edge, i) => {
            return (
              <div key={i} className="item">
                <h2 className="item-title">{edge.node.titulo}</h2>
                <div className="item-media-date">
                  {edge.node.medio}, {dateLarge(edge.node.fecha)}
                </div>
                <div
                  className="item-description"
                  dangerouslySetInnerHTML={{
                    __html: edge.node.descripcion.childMarkdownRemark.html,
                  }}
                />
                <a
                  className="item-download"
                  href={edge.node.pdf ? edge.node.pdf.file.url : null}
                >
                  <i className="material-icons">get_app</i>
                  <span>descargar</span>
                </a>
              </div>
            )
          })}
        </main>
      </div>
    </Page>
  )
}

export const articulosAcademicosListQuery = graphql`
  query {
    allContentfulArticulosAcademicos(sort: { fields: fecha, order: DESC }) {
      edges {
        node {
          titulo
          fecha
          medio
          descripcion {
            childMarkdownRemark {
              html
            }
          }
          pdf {
            file {
              url
            }
          }
        }
      }
    }
  }
`
